<template>
  <div>
    <h4>Seguimiento de solicitudes</h4>
    <p class="text-justify">
      <b>Proporciona los datos para mostrar solicitudes.</b>
    </p>

    <div class="form-row grupo">
      <div class="form-group col-md-4">
        <label>Captación:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="busqueda.id_captacion"
        >
          <option value="" selected>Todos</option>
          <option
            v-for="cap in captaciones"
            :key="cap.id_captacion"
            v-bind:value="cap.id_captacion"
          >
            {{ cap.captacion }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label>Tipo solicitud:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="busqueda.id_tiposolicitud"
        >
          <option value="" selected>Todos</option>
          <option
            v-for="tipo in tiposolicitud"
            :key="tipo.id_tiposolicitud"
            v-bind:value="tipo.id_tiposolicitud"
          >
            {{ tipo.tiposolicitud }}
          </option>
        </select>
      </div>      
      <div class="form-group col-md-4">
        <label>Estatus solicitud:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="busqueda.id_estatus"
        >
          <option value="" selected>Todos</option>
          <option
            v-for="e in estatus"
            :key="e.id_estatus"
            v-bind:value="e.id_estatus"
          >
            {{ e.estatus }}
          </option>
        </select>
      </div>      
      <div class="form-group col-md-4">
        <label>Prioridad solicitud:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="busqueda.id_prioridad"
        >
          <option value="" selected>Todos</option>
          <option
            v-for="prioridad in prioridades"
            :key="prioridad.id_prioridad"
            v-bind:value="prioridad.id_prioridad"
          >
            {{ prioridad.prioridad }}
          </option>
        </select>
      </div>      
      <div class="form-group col-md-4">
        <label>Dependencia:</label>
        <select
          class="form-control"
          style="max-width: 500px"
          v-model="busqueda.id_area"
        >
          <option value="" selected>Todos</option>
          <option
            v-for="area in areas"
            :key="area.id_area"
            v-bind:value="area.id_area"
          >
            {{ area.area }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label>Folio</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="busqueda.id_solicitud"
          class="form-control"
        />
      </div>
      <div class="form-group col-md-4">
        <label>Fecha inicial</label>
        <b-form-datepicker
            v-model="busqueda.fechai"
            placeholder="Todas"
          ></b-form-datepicker>

      </div>
      <div class="form-group col-md-4">
        <label>Fecha final</label>
                <b-form-datepicker
            v-model="busqueda.fechaf"
            placeholder="Todas"
          ></b-form-datepicker>

      </div>
      <!--      
      <div class="form-group col-md-4">
        <label>Teléfono</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="busqueda.telefono"
          class="form-control"
        />
      </div>
      <div class="form-group col-md-4">
        <label>CURP</label>
        <input
          maxlength="100"
          style="max-width: 500px"
          v-model.trim="busqueda.curp"
          class="form-control"
        />
      </div>
      -->
      <div class="form-group col-md-12 text-right">
        <button class="btn btn-primary" v-on:click.stop.prevent="buscar">
          Buscar solicitud
        </button>
      </div>
    </div>
    <div v-show="buscando" >
      <h4>Solicitudes encontradas</h4>
      <div class="table-responsive">
        <table class="table table-condensed">
          <thead>
            <th>Nombre</th>
            <th></th>
            <th>Dias</th>
            <th>Vence en</th>
            <th>Estatus</th>
            <th>Prioridad</th>
            <th>Captación</th>
            <th>Tipo solicitud</th>
            <th>Dependencia</th>
            <th>Fecha</th>
            <th></th>
          </thead>
          <tr v-for="solicitud in solicitudes" :key="'s-'+solicitud.id_solicitud">
            <td>{{solicitud.paterno}} {{solicitud.materno}} {{solicitud.nombre}}</td>
            <td><b-icon icon="exclamation-circle-fill" :variant="solicitud.id_estatus == 7 ? 'success' : (solicitud.dias > solicitud.diaslimite ? 'danger' : (solicitud.id_estatus   > 1 ? 'secondary' : 'warning'))"></b-icon></td>
            <td>{{solicitud.dias}}</td>
            <td>{{solicitud.horasvence}} hrs.</td>
            <td>{{solicitud.estatus}}</td>
            <td>{{solicitud.prioridad}}</td>
            <td>{{solicitud.captacion}}</td>
            <td>{{solicitud.tiposolicitud}}</td>
            <td>{{solicitud.area}}</td>
            <td>{{solicitud.fecha}}</td>
            <td>
              <a href="javascript:;" @click="abrirPdf(solicitud)"><font-awesome-icon size="lg" icon="file-pdf" /></a>&nbsp;
              <a href="javascript:;" @click="abrirDetalle(solicitud)"><font-awesome-icon size="lg" icon="edit" /></a>&nbsp;
              <a href="javascript:;" @click="abrirAvance(solicitud)"><font-awesome-icon size="lg" icon="info-circle" /></a>&nbsp;
              <a href="javascript:;" @click="agregarAvance(solicitud)"><font-awesome-icon size="lg" icon="plus-square" /></a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <b-modal size="xl" ref="detalle" title="Detalle de solicitud">
      <solicitud-ver ref="detalle2" :solicitud="solicitud" />
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal size="xl" ref="avance" title="Avance de solicitud">
      <solicitud-avance ref="avance2" :solicitud="solicitud" @editar="editarAvance"/>
      <template #modal-footer="{ hide }">
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
    <b-modal size="xl" ref="avancemas" title="Agregar avance a solicitud">
      <solicitud-avance-mas ref="mas" :solicitud="solicitud" :avance-editar="avance" />
      <template #modal-footer="{ hide }">
        <b-button type="primary" class="float-right" @click="guardarAvance"> Guardar </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import {PDF_URL} from '@/api'
import SolicitudVer from "@/views/SolicitudVer"
import SolicitudAvance from "@/views/SolicitudAvance"
import SolicitudAvanceMas from "@/views/SolicitudAvanceMas"


export default {
  name: "Estatus",
  components: {SolicitudVer, SolicitudAvance, SolicitudAvanceMas},
  data() {
    return {
      tiposolicitud: [],
      estatus: [],
      prioridades: [],
      areas: [],
      busqueda: { id_tiposolicitud: '', id_estatus: 1, id_prioridad: '', id_area: '', id_captacion: ''},
      solicitudes: [],
      buscando: false,
      solicitud: { },
      avance: null,
      captaciones: [],
    };
  },
  methods: {
    buscar: function () {
      this.buscando = true;
      api
        .buscarSolicitudes(this.busqueda)
        .then((r) => {
          this.solicitudes = r.data;
        })
        .catch(() => {
          this.solicitudes = [];
        });
    },
    abrirPdf(s) {
      this.solicitud = s
      window.open(`${PDF_URL}/solicitud.php?id=${s.id_solicitud}`,'_blank');
    },
    abrirDetalle(s) {
      api
        .abrirSolicitud(s.id_solicitud)
        .then((respuesta) => {
          this.solicitud = respuesta.data;
        });
      this.$refs.detalle.show();
    },
    abrirAvance(s) {
      //this.$set(this.solicitud, )
      this.solicitud = s
      this.$refs.avance.show();
    },
    agregarAvance(s) {
      this.solicitud = s;
      this.avance = null;
      this.$refs.avancemas.show();
    },
    editarAvance(s, a) {
      this.solicitud = s;
      this.avance = a;
      this.$refs.avancemas.show();
    },
    guardarAvance() {
        this.$refs.mas.guardar(this.$parent).then(() => {
          if (this.avance != null) {
            this.$refs.avance2.actualiza();
            this.$refs.avancemas.hide();
          } else {
            this.buscar();
            this.$refs.avancemas.hide();
          }
          });
    },
    actualizar() {
        var id = this.$route.params.id;
        if (typeof id === "undefined") {
          id = 0;
        }
        console.log(id);
        this.buscar(id);
    },
  },
  mounted() {
    api
      .listarCaptaciones()
      .then((respuesta) => (this.captaciones = respuesta.data));
    api
      .listarTipoSolicitud()
      .then((respuesta) => (this.tiposolicitud = respuesta.data));
    api
      .listarPrioridades()
      .then((respuesta) => (this.prioridades = respuesta.data));
    api
      .listarEstatusSolicitud()
      .then((respuesta) => (this.estatus = respuesta.data));
    api
      .listarAreas()
      .then((respuesta) => (this.areas = respuesta.data));
      var id = this.$route.params.id;
      if (typeof id !== "undefined") {
        this.busqueda.id_solicitud = id;
      }
    this.buscar();

  }
};
</script>

